import { NgModule } from '@angular/core';
import { TodaysLiveScheduleComponent } from './todays-live-schedule/todays-live-schedule.component';
import { TYTCommonModule } from '@tytapp/common';
import { CommonUiModule } from '@tytapp/common-ui';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentBlocksService } from '@tytapp/content';
import { LIVE_SCHEDULE_BLOCK_TYPE } from './live-schedule-block';

const DECLARATIONS = [
    TodaysLiveScheduleComponent
]
@NgModule({
    declarations: DECLARATIONS,
    exports: DECLARATIONS,
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TYTCommonModule,
        CommonUiModule,
    ]
})
export class LiveUiModule {
    constructor() {
        ContentBlocksService.register(LIVE_SCHEDULE_BLOCK_TYPE);
    }
}