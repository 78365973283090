<div class="list">

  @if (state === 'loading') {
    @for (n of [].constructor(8); track n) {
      <a class="item placeholder">
        <div class="live-indicator">• Live</div>
        <picture responsive [width]="32" class="placeholder"></picture>
        <div class="info">
          <div class="title">
            <span class="placeholder"></span>
          </div>
          <time><span class="placeholder"></span></time>
        </div>
      </a>
    }
  }
  @if (state === 'ready') {
    @for (liveSchedule of limitArray(upcomingEvents, 8); track liveSchedule) {
      <a
        class="item" [class.live]="getEventStream(liveSchedule)" [routerLink]="liveStreamRoute(liveSchedule)">
        <div class="live-indicator">• Live</div>
        <picture
          responsive
          [src]="liveSchedule.show.logo_square"
          [width]="64"
          aria-hidden="true"
        ></picture>
        <div class="info">
          <div class="title">{{liveSchedule.title}}</div>
          <time [attr.datetime]="airTimeForItem(liveSchedule) | isoDate">
            {{liveSchedule.day}} {{liveSchedule.air_time_unix * 1000 | date : 'h:mm a' : timezone}}
            {{timezoneString}}
          </time>
        </div>
      </a>
    }
  }
</div>