import { ContentBlockType } from '@tytapp/content';
import { LiveScheduleBlock } from './live-schedule-block';

export * from './live-schedule-block';

export const LIVE_SCHEDULE_BLOCK_TYPE: ContentBlockType<LiveScheduleBlock> = {
    id: 'live-schedule',
    label: 'Today\'s Live Schedule',
    icon: 'live_tv',
    editable: false,
    template: {
    },
    preview: {
    },
    deferredEditorComponent: () => import('./live-schedule-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./live-schedule-block.view').then(m => m.ViewComponent)
}
